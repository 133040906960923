import React, { useState, useRef, useEffect } from 'react';
import VideoGrid from './VideoGrid';
import LabelSelector from './LabelSelector';
import Login from './Login';
import './App.css';

const movementLabels = [
  { name: 'down', color: 'red', key: '1' },
  { name: 'bottom', color: 'blue', key: '2' },
  { name: 'up', color: 'green', key: '3' },
  { name: 'top', color: 'goldenrod', key: '4' },
  { name: 'stretching', color: 'cyan', key: '5' }
];

const formLabels = [
  { name: 'top_wide', color: 'red', key: '1' },
  { name: 'top_good', color: 'green', key: '2' },
  { name: 'top_narrow', color: 'purple', key: '3' },
  { name: 'bottom_shallow', color: 'goldenrod', key: '4' },
  { name: 'bottom_good', color: 'aquamarine', key: '5' },
  { name: 'bottom_deep', color: 'olivedrab', key: '6' },
];

function App() {
  const [isPlaying, setIsPlaying] = useState(true);
  const [playbackSpeed, setPlaybackSpeed] = useState(0.25);
  const [labelAssignments, setLabelAssignments] = useState({});
  const [selectedLabel, setSelectedLabel] = useState('');
  const [workoutTypes, setWorkoutTypes] = useState([]);
  const [selectedWorkoutType, setSelectedWorkoutType] = useState('');
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentClips, setCurrentClips] = useState([]);  
  const [originalClipIds, setOriginalClipIds] = useState({});
  const [annotationStarted, setAnnotationStarted] = useState(false);

  const [labelType, setLabelType] = useState('movement');
  const [updatedLabels, setUpdatedLabels] = useState(movementLabels);
  

  const videoRefs = useRef([]);

  const [isSettingsVisible, setIsSettingsVisible] = useState(false);

  const toggleSettingsMenu = () => {
    setIsSettingsVisible(!isSettingsVisible);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') || '');

  const onLoginSuccess = (token) => {
    localStorage.setItem('authToken', token);
    setAuthToken(token);
    setIsAuthenticated(true);
    console.log('Login successful' + token);
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    videoRefs.current.forEach(video => {
      if (video) {
        isPlaying ? video.pause() : video.play();
      }
    });
  };

  const changePlaybackSpeed = (speed) => {
    setPlaybackSpeed(speed);
    videoRefs.current.forEach(video => {
      if (video) {
        video.playbackRate = speed;
      }
    });
  };

  const updateLabels = (workoutType, labelType) => {
    const labels = labelType === 'movement' ? movementLabels : formLabels;
    const newLabels = labels.map(label => {
      if (label.name !== 'stretching') {
        return { ...label, name: `${workoutType}_${label.name}` };
      }
      return label;
    });
    setUpdatedLabels(newLabels);
  };

  const assignLabelToVideo = (clipId) => {
    const labelColor = getLabelColor(selectedLabel);
    setLabelAssignments(prev => ({
      ...prev,
      [clipId]: { label: selectedLabel, color: labelColor }
    }));
  };
  
  const clearLabelFromVideo = (clipId) => {
    setLabelAssignments(prev => {
      const newAssignments = { ...prev };
      delete newAssignments[clipId];
      return newAssignments;
    });
  };

  const handleWorkoutTypeChange = (e) => {
    const newWorkoutType = e.target.value;
    setSelectedWorkoutType(newWorkoutType);
    updateLabels(newWorkoutType, labelType);
  };
  
  const handleLabelTypeChange = (e) => {
    const newLabelType = e.target.value;
    setLabelType(newLabelType);
    updateLabels(selectedWorkoutType, newLabelType);
  };

  const getLabelColor = (labelName) => {
    const label = updatedLabels.find(l => l.name === labelName);
    return label ? label.color : 'transparent'; // Replace 'defaultColor' with a fallback color
  };

  const fetchClips = async (videoId) => {
    const formattedId = videoId.replace('uploads/', '');
    try {
      const response = await fetch(`https://api.eq.fitness/clips/${formattedId}`,  {
        headers: {
        'Authorization': `${authToken}`,
        }
      });
      const clipIds = await response.json();
      const clipUrls = {};
      const fetchedClipUrls = await Promise.all(clipIds.map(async (clipId) => {
        const encodedClipId = encodeURIComponent(clipId);
        const blobResponse = await fetch(`https://api.eq.fitness/clip/${encodedClipId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${authToken}`,
          }
        });
        const blob = await blobResponse.blob();
        const blobUrl = URL.createObjectURL(blob);
        clipUrls[blobUrl] = clipId; // Map blob URL to original clip ID
        return blobUrl;
      }));
      setOriginalClipIds(clipUrls);
      setCurrentClips(fetchedClipUrls);
    }
    catch (error) {
      console.error('Error fetching clips:', error);
    }
  };
  

  const handleStartAnnotation = async () => {
    try {
      const response = await fetch('https://api.eq.fitness/videos/annotation/claim', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${authToken}`,
        },
        body: JSON.stringify({ secretKey: 'yeet_XXX_god', type: selectedWorkoutType }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setCurrentVideo(data);
      fetchClips(data.key);
      setAnnotationStarted(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSaveAnnotation = async () => {
    if (window.confirm("Are you sure you want to save?")) {
      const jsonData = generateJsonData();
  
      // Create a Blob from your JSON data
      const file = new Blob([jsonData], { type: 'application/json' });
      const formData = new FormData();
  
      // Append the file and other data to the FormData object
      formData.append('payload', file);
      formData.append('secretKey', 'yeet_XXX_god');
      formData.append('fileLocation', currentVideo.key); // Assuming currentVideo.key is the file location
  
      try {
        const response = await fetch('/videos/annotation/submit', {
          method: 'POST',
          body: formData, // Send the FormData object
          headers: {
            'Authorization': `${authToken}`,
          },
          // Do not set Content-Type header, the browser will set it with the correct boundary
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        // Handle response here
        resetAppState();
      } catch (error) {
        console.error('Error:', error);
        // Handle error here
      }
    }
  };

  const handleProcessVideo = async () => {
    try {
        const response = await fetch('/videos/process', {
            method: 'POST',
            headers: {
                'Authorization': `${authToken}`,
                'Content-Type': 'application/json'
            },
        });
        const data = await response.text();
        alert(data);
    } catch (error) {
        console.error('Error:', error);
    }
};

  // Function to reset the app state
  const resetAppState = async () => {
    setAnnotationStarted(false);
    setCurrentVideo(null);
    setCurrentClips([]);
    setLabelAssignments({});
    setOriginalClipIds({});

    // Fetch the workout types again to refresh the dropdown
    await fetchWorkoutTypes();
  };

    // Function to handle discard annotation
    const handleDiscardAnnotation = async () => {
      if (window.confirm("Are you sure you want to discard?")) {
        // Call your API to discard annotations
        // Example POST request
        await fetch('/videos/annotation/discard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${authToken}`,
          },
          body: JSON.stringify({
            secretKey: 'yeet_XXX_god',
            fileLocation: currentVideo.key
          }),
        });
        setAnnotationStarted(false);
        // Refresh workout types and reset states as necessary
        resetAppState();
      }
    };
  
    // Function to generate JSON data with correct clip_ids
    const generateJsonData = () => {
      const data = Object.entries(labelAssignments).map(([blobUrl, labelInfo]) => {
        const clipId = originalClipIds[blobUrl];
        return { clip_id: clipId, label: labelInfo.label };
      });
      return JSON.stringify(data); // Stringify the JSON object
    };
    

    const fetchWorkoutTypes = async () => {
      console.log("fetch workout types" +authToken);
      try {
        const response = await fetch('https://api.eq.fitness/videos/types/status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${authToken}`,
          },
          body: JSON.stringify({ secretKey: 'yeet_XXX_god', status: 'processed' }),
        });
    
        if (!response.ok) {
          if (response.status === 401 || response.status == 403) {
            setIsAuthenticated(false);
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
    
        const data = await response.json();
        setWorkoutTypes(data);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };
    

  useEffect(() => {
    fetchWorkoutTypes();
  }, [authToken]);

  return (
    <div className="App">
      {!isAuthenticated ? (
        <Login onLoginSuccess={onLoginSuccess} />
      ) : (
        <>
        <div className="controls">
        <div>
          <select
            value={selectedWorkoutType}
            onChange={handleWorkoutTypeChange}
            disabled={annotationStarted}
          > 
          <option value="">Select a Workout Type</option>
          {workoutTypes.map((type) => (
            <option key={type.type} value={type.type}>
              {type.type} ({type.numAvailable})
            </option>
          ))}
          </select>
          {!annotationStarted && (
            <button onClick={handleStartAnnotation} style={{ backgroundColor: 'green' }}>
              Start
            </button>
          )}
          {annotationStarted && (
            <>
              <button onClick={handleSaveAnnotation} style={{ backgroundColor: 'green' }}>
                Save
              </button>
              <button onClick={handleDiscardAnnotation} style={{ backgroundColor: 'red' }}>
                Discard
              </button>
            </>
          )}
           <div className="label-type-selector">
        <label>
          <input
            type="radio"
            value="movement"
            checked={labelType === 'movement'}
            onChange={handleLabelTypeChange}
          />
          Movement
        </label>
        <label>
          <input
            type="radio"
            value="form"
            checked={labelType === 'form'}
            onChange={handleLabelTypeChange}
          />
          Form
        </label>
      </div>
        </div>
          <button className="settings-button" onClick={toggleSettingsMenu}>⚙️</button>
          <div className="settings-menu" style={{ display: isSettingsVisible ? 'block' : 'none' }}>
            <button onClick={togglePlay}>{isPlaying ? 'Pause' : 'Play'}</button>
            <button onClick={() => changePlaybackSpeed(0.25)}>0.25x</button>
            <button onClick={() => changePlaybackSpeed(0.5)}>0.5x</button>
            <button onClick={() => changePlaybackSpeed(1)}>1x</button>
            <button onClick={handleProcessVideo}>Process New Video</button>
          </div>
        <LabelSelector labels={updatedLabels} onLabelSelect={setSelectedLabel} />
        </div>
        <VideoGrid
          ref={videoRefs}
          isPlaying={isPlaying}
          playbackSpeed={playbackSpeed}
          selectedLabel={selectedLabel}
          selectedLabelColor={getLabelColor(selectedLabel)}
          assignLabelToVideo={assignLabelToVideo}
          clearLabelFromVideo={clearLabelFromVideo}
          labelAssignments={labelAssignments}
          clips={currentClips}
        />  
        </>  
    )}
    </div>
    );
}

export default App;
