// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-selector {
    display: flex;
    justify-content: right;
    margin: 20px;
    padding-right: 24px;
    flex: 1 1; /* Each item will take equal space */
  }
  
  .label-selector button {
    color: #FFFFFF;
    font-weight: 700;
    margin: 0 10px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    opacity: 0.8; /* Reduced opacity for non-selected buttons */
    transition: opacity 0.3s ease; /* Smooth transition for opacity changes */
  }
  
  .label-selector .selected {
    border: 2px solid white;
    opacity: 1; /* Full opacity for the selected button */
  }
  `, "",{"version":3,"sources":["webpack://./src/LabelSelector.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,SAAO,EAAE,oCAAoC;EAC/C;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,YAAY,EAAE,6CAA6C;IAC3D,6BAA6B,EAAE,0CAA0C;EAC3E;;EAEA;IACE,uBAAuB;IACvB,UAAU,EAAE,yCAAyC;EACvD","sourcesContent":[".label-selector {\n    display: flex;\n    justify-content: right;\n    margin: 20px;\n    padding-right: 24px;\n    flex: 1; /* Each item will take equal space */\n  }\n  \n  .label-selector button {\n    color: #FFFFFF;\n    font-weight: 700;\n    margin: 0 10px;\n    padding: 5px 10px;\n    border: none;\n    cursor: pointer;\n    opacity: 0.8; /* Reduced opacity for non-selected buttons */\n    transition: opacity 0.3s ease; /* Smooth transition for opacity changes */\n  }\n  \n  .label-selector .selected {\n    border: 2px solid white;\n    opacity: 1; /* Full opacity for the selected button */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
