import React, { useState, useEffect, forwardRef, useRef } from 'react';
import './VideoGrid.css';

const VideoGrid = forwardRef(({ clips, assignLabelToVideo, clearLabelFromVideo, labelAssignments, isPlaying, playbackSpeed }, ref) => {
    const [currentPage, setCurrentPage] = useState(0);
    const videoWidth = 240; // Width of each video
    const margin = 10; // Margin between videos, adjust as needed
    const containerWidth = useRef(window.innerWidth); // or a specific width if you have a container
    const videoHeight = 360; // Assuming height is the same as width
    const containerHeight = useRef(window.innerHeight);

    const calculateVideosPerPage = () => {
        const columns = Math.floor(containerWidth.current / (videoWidth + margin));
        const rows = Math.floor((containerHeight.current) / (videoHeight + margin));
        return columns * rows;
    };
    
    const [videosPerPage, setVideosPerPage] = useState(calculateVideosPerPage());
    const totalPages = Math.ceil(clips.length / videosPerPage);

    const goToNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'b' && currentPage > 0) {
          setCurrentPage(currentPage - 1);
        } else if (event.key === 'n' && currentPage < totalPages - 1) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
      }, [currentPage, totalPages]);
      

    useEffect(() => {
        const handleResize = () => {
            containerWidth.current = window.innerWidth;
            containerHeight.current = window.innerHeight;
            const newVideosPerPage = calculateVideosPerPage();
            setVideosPerPage(newVideosPerPage);
    
            // Adjust current page if needed
            const newTotalPages = Math.ceil(clips.length / newVideosPerPage);
            if (currentPage >= newTotalPages) {
                setCurrentPage(newTotalPages - 1); // Move to the last page if the current page is out of range
            }

            if (ref.current) {
                ref.current.forEach((video, index) => {
                  if (video) {
                    // Play or pause videos based on the isPlaying state
                    isPlaying ? safelyPlayVideo(video) : video.pause();
                    playbackSpeed ? video.playbackRate = playbackSpeed : video.playbackRate = 1;
                  }
                });
            }
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, [currentPage, clips.length, isPlaying, playbackSpeed]);

      const safelyPlayVideo = (videoElement) => {
        if (!videoElement) return;
      
        const onVideoReady = () => {
          const playPromise = videoElement.play();
          if (playPromise !== undefined) {
            playPromise.then(() => {
              // Video is playing successfully
            }).catch(error => {
              console.warn("Play request was interrupted:", error);
            });
          }
        };
      
        if (videoElement.readyState >= 3) { // 3 = HAVE_FUTURE_DATA
          onVideoReady();
        } else {
          videoElement.addEventListener('loadeddata', onVideoReady, { once: true });
        }
      };      
    
      useEffect(() => {
        // Adjust video playback based on currentPage
        if (ref.current) {
          ref.current.forEach((video, index) => {
            if (video) {
              // Play or pause videos based on the isPlaying state
              playbackSpeed ? video.playbackRate = playbackSpeed : video.playbackRate = 1;
              isPlaying ? safelyPlayVideo(video) : video.pause();
            }
          });
        }
      }, [currentPage, isPlaying, playbackSpeed]);
    
      useEffect(() => {
        if (ref.current) {
          ref.current = ref.current.slice(0, clips.length);
        }
      }, [clips, ref]);
    
      const handleVideoClick = (clipId, isRightClick = false) => {
        if (isRightClick) {
          clearLabelFromVideo(clipId);
        } else {
          assignLabelToVideo(clipId); // No need to pass the label, it's taken from App.js state
        }
      };


      const startIndex = currentPage * videosPerPage + 1;
      const endIndex = Math.min(startIndex + videosPerPage - 1, clips.length);

      return (
        <div>
        <div className="video-grid">
        {clips.slice(currentPage * videosPerPage, (currentPage + 1) * videosPerPage).map((clipUrl, index) => {
            const absoluteIndex = currentPage * videosPerPage + index;
            const clipId = clips[absoluteIndex];
            const labelInfo = labelAssignments[clipId];
            return (
              <video
                key={index}
                src={clipUrl}
                loop
                onClick={() => handleVideoClick(clipId)}
                onContextMenu={(e) => {
                  e.preventDefault();
                  handleVideoClick(clipId, true);
                }}
                onLoadedMetadata={e => { if (isPlaying) e.target.play(); e.target.playbackRate = playbackSpeed; }}
                muted
                style={{
                  maxWidth: '240px',
                  border: labelInfo ? `6px solid ${labelInfo.color}` : '6px solid transparent'
                }}
                ref={el => ref.current && (ref.current[index] = el)}
              />
            );
          })}
        </div>
        <div className="pagination-controls">
            <button onClick={goToPreviousPage} disabled={currentPage === 0}>Previous (B)</button>
            <span class="indicators"> Page {currentPage + 1} of {totalPages} </span>
            <button onClick={goToNextPage} disabled={currentPage === totalPages - 1}>Next (N)</button>
        </div>
        </div>
      );
        });
    
    export default VideoGrid;