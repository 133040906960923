// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 98vh; /* Full viewport height */
    align-content: flex-start; /* Align columns to the start */
  }
  
  
  
  .pagination-controls {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0; /* Add some padding */
    text-align: center; /* Center the buttons */
  }`, "",{"version":3,"sources":["webpack://./src/VideoGrid.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,YAAY,EAAE,yBAAyB;IACvC,yBAAyB,EAAE,+BAA+B;EAC5D;;;;EAIA;IACE,eAAe;IACf,SAAS;IACT,OAAO;IACP,WAAW;IACX,eAAe,EAAE,qBAAqB;IACtC,kBAAkB,EAAE,uBAAuB;EAC7C","sourcesContent":[".video-grid {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    height: 98vh; /* Full viewport height */\n    align-content: flex-start; /* Align columns to the start */\n  }\n  \n  \n  \n  .pagination-controls {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    padding: 10px 0; /* Add some padding */\n    text-align: center; /* Center the buttons */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
