import React, { useState } from 'react';
import './LabelSelector.css';

function LabelSelector({ labels, onLabelSelect }) {
  const [selectedLabel, setSelectedLabel] = useState('');

  const handleLabelClick = (label) => {
    setSelectedLabel(label.name);
    onLabelSelect(label.name);
  };

  const handleKeyDown = (event) => {
    const label = labels.find(label => label.key === event.key);
    if (label) {
      setSelectedLabel(label.name);
      onLabelSelect(label.name);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [labels, onLabelSelect]); 

  return (
    <div className="label-selector">
      {labels.map((label) => (
        <button
          key={label.name}
          style={{ backgroundColor: label.color }}
          className={selectedLabel === label.name ? 'selected' : ''}
          onClick={() => handleLabelClick(label)}
        >
          {label.name} ({label.key})
        </button>
      ))}
    </div>
  );
}

export default LabelSelector;
